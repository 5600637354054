<template>
  <v-container class="fill-height" fluid>
    <p>{{ $t("Disconnected") }}</p>
  </v-container>
</template>
<script>
export default {
    name: 'Logout',
    
    mounted() {
        this.$store.dispatch('auth/logout').then(() => {
            this.$router.push('/')
        })
    }
}
</script>
<style scoped>

</style>